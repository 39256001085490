//=================================================================================================
// Buttons in general
//=================================================================================================
#{$btn} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin: 5px 14px 5px 0;
    padding: 17px 30px;
    background: $color-main;
    border: none;
    border-radius: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $color-dark;
    text-transform: $btn-text-transform;
    text-decoration: none;
    text-align: center;
    transition: all $duration ease;

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        color: $color-white;
        background: $color-second;

        svg {
            fill: $color-white;
        }
    }

    svg {
        width: 26px;
        height: 26px;
        fill: $color-dark;
        transition: all $duration ease;
    }
}


//=================================================================================================
// Buttons specifics
//=================================================================================================
.button {
    &--filter,
    &--toggler,
    &--toggler:active,
    &--toggler:focus {
        font-size: $btn-spe-font-size;
        font-weight: $btn-spe-font-weight;
        line-height: $btn-spe-line-height;
        padding: $btn-spe-padding;
        border-radius: $btn-spe-border-radius;
        height: 40px;
    }


    //===============================================
    // Button secondary (borders, neutral)
    //===============================================
    &--secondary {
        background-color: $color-second;
        color: $color-white;

        svg {
            fill: $color-white
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background-color: $color-main;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }
        }
    }


    //===============================================
    // Button variant 1 (Background and color change)
    //===============================================
    &--variant {
        color: $btn-variant-color;
        background: $btn-variant-bg;

        svg {
            fill: $btn-variant-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-variant-color--hover;
            background: $btn-variant-bg--hover;

            svg {
                fill: $btn-variant-color--hover;
            }
        }
    }


    //===============================================
    // Button filter
    //===============================================
    &--filter {
        color: $color-dark;
        background: $color-light;
        margin-right: 10px;
        padding: 8px 15px;
        font-family: $font-family--heading;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;

        svg {
            color: $color-dark;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background-color: $color-main;
            color: $color-dark;
        }
    }


    //===============================================
    // Button toggler
    //===============================================
    &--toggler,
    &--toggler:active,
    &--toggler:focus {
        background: transparent;
        border: 1px solid $color-dark;
        color: $color-dark;
        font-family: $font-family--heading;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;

        svg {
            fill: $color-dark;
        }

        &.is-open {
            svg {
                transform: rotate(180deg);
            }
        }

        &:hover {
            color: $color-white;
            background: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }


    //===============================================
    // Button tag
    //===============================================
    // &--tag,
    // &--tag--medium,
    // &--tag--low {
    //     margin: 0 5px 10px 0;
    //     background-color: $btn-tag-bg;
    //     color: $btn-tag-color;

    //     &:hover, &:focus {
    //         background-color: $btn-tag-bg--hover;
    //         color: $btn-tag-color--hover;
    //     }
    // }

    // &--tag--medium {
    //     opacity: 0.8;
    // }

    // &--tag--low {
    //     opacity: 0.6;
    // }


    //===============================================
    // Button empty
    //===============================================
    &--empty {
        background: transparent;
        color: $color-dark;
        border-radius: 50px;
        padding: 8px 15px;
        border: 1px solid $color-dark;
        font-family: $font-family--heading;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        margin: 5px 10px 5px 0;

        svg {
            fill: $color-dark;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background: $color-dark;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }
}


//===============================================
// Specifics links
//===============================================
.link-document {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    cursor: pointer;
    font-family: $font-family--heading;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-transform: none;
    color: $color--card;
    transition: all $duration ease;
    position: relative;

    svg {
        width: 30px;
        height: 30px;
        background: $color-bg--neutral;
        border-radius: 50%;
        fill: $color-text;
        margin-right: 10px;
    }

    &:before {
        position: absolute;
        content: " ";
        width: calc(100% - 40px);
        height: 1px;
        bottom: 3px;
        right: 0;
        background-color: $color-dark;
        transition: all $duration ease;
    }

    &:hover {
        &:before {
            height: 0;
        }
    }
}


//===============================================
// Animation Voir plus
//===============================================
.showX {
    opacity: 1;
    transition: all 0.30s ease-out;
}

.hideX {
    opacity: 0;
    transition: all 0.30s ease-in;
    pointer-events: none;
}

.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

//===============================================
// Reset button
//===============================================
.reset-button-style {
    display: inherit;
    margin: 0;
    padding: 0;
    height: auto;
    background: inherit;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
    text-align: inherit;
    transition: none;

    svg {
        width: inherit;
        height: inherit;
        fill: inherit;
        margin: 0;
        transition: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background: inherit;
        border-color: inherit;
        color: inherit;

        svg {
            fill: inherit;
        }
    }
}