.nav-main {
    z-index: 20;

    &__container {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        z-index: 0;
        right: 0;
        top: 0;
        transform: translateY(-100vh);
        height: 100vh;
        width: 100vw;
        background: $color-light;
        overflow: hidden;
        transition: .5s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: calc(50% - 185px);
            background: #F4F4F4;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }

        .container {
            position: relative;
            width: 100%;
        }
    }

    &__logo {
        position: absolute;
        top: 50px;
        display: flex;
        gap: 12px;

        svg {
            width: 62.74px;
            height: 66px;
        }

        > div {
            font-family: $font-family;
            font-weight: 700;
            font-size: 55px;
            line-height: 55px;
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            margin-top: -2px;
            letter-spacing: 0.31px;

            span  {
                font-weight: 100;
                font-size: 16px;
                line-height: 25px;
                margin-left: 3px;
                letter-spacing: 0.4px;
            }
        }
    }

    .menu__nav__list {
        z-index: 15;
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        width: 325px;
        height: auto;
        margin-top: 220px;
        transition: all $duration ease-in-out;

        .nav-main-item {

            &.active {
                .sub-menu {
                    display: flex;
                }

                &.menu-item-has-children {
                    .nav-main-link {
                        svg {
                            background-color: $color-main;
                        }
                    }
                }
            }

            a {
                transition: all 1.7s ease-in-out;
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                font-family: $font-family;
                font-weight: 700;
                font-size: 30px;
                line-height: 30px;
                width: 100%;
                opacity: 1;
                margin-bottom: 50px;
                color: $color-dark;
                gap: 15px;
                transition: all $duration ease-in-out;

                svg {
                    width: 20px;
                    height: 20px;
                    background-color: $color-white;
                    border-radius: 50px;
                    margin-top: 4px;
                    transition: all $duration ease-in-out;
                }

                &:hover {
                    svg  {
                        background-color: $color-main;
                    }
                }
            }
    
            .sub-menu {
                display: none;
                position: absolute;
                top: 5px;
                left: 470px;
                width: 300px;
                transition: all $duration ease-in-out;

                .nav-drop {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    position: relative;
                    max-height: 565px;
                    columns: 300px 2;
                    gap: 10px 65px;

                    &__title {
                        display: none;
                        font-family: $font-family--heading;
                        font-weight: $font-weight-normal;
                        font-size: 20px;
                        line-height: 20px;
                        position: absolute;
                        color: $color-main;
                        transform: rotate(-90deg);
                        transform-origin: right;
                        top: -10px;
                        left: -365px;
                        opacity: .4;
                        text-transform: uppercase;
                        width: 320px;
                        height: 20px;
                        text-align: end;
                    }
                }

                .nav-child-item, .nav-grandchild-item {
                    position: relative;
                    font-family: $font-family--heading;
                    font-weight: $font-weight-light;
                    font-size: $font-size--5;
                    line-height: 20px;
                    color: $color-text;
                    transition: all $duration ease-in-out;

                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding-bottom: 19px;
                        z-index: 3;
                        transition: all $duration ease-in-out;

                        span {
                            width: 5px;
                            height: 5px;
                            min-width: 5px;
                            background-color: $color-dark;
                            border-radius: 50px;
                        }
                    }

                    &:hover {
                        margin-left: 10px;

                        a span {
                            background-color: $color-main
                        }
                    }
                }
            }

            &.active,
            &.menu-item-has-children.active {
                .sub-menu {
                    display: block;
                }
            }
        }
    }

    &__button {
        display: none;

        ~ .menu {
            clear: both;
            transform: scaleY(0);
            transform-origin: 50% 0;
            transition:transform .3s ease;
        }

        &:checked {
            & ~ .nav-main__container {
                transform: translateY(0);

                .container .menu {
                    transform: scaleY(1);
                    display: flex;
                }
            }

            & ~ .nav-main__icon {

                .nav-main__icon--container {
                    width: 94.1px;

                    &:after {
                        content: "Fermer";
                        position: absolute;
                        bottom: 0;
                        left: 25px;
                        transform: translateX(-50%);
                    }
                }

                .nav-main__icon__svg-container {
                    width: 0;

                    &:before, &:after {
                        top: 0;
                    }

                    &:before {
                        transform: rotate(-35deg);
                    }

                    &:after {
                        transform: rotate(35deg);
                    }
                }
            }

        }
    }

    &__icon {
        position: relative;
        z-index: 1;
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
        margin-left: 0;
        background-color: transparent;

        &--container {
            display: flex;
            align-items: flex-start;
            flex-direction: row-reverse;
            height: 33px;
            gap: 10px;
            font-family: $font-family;
            font-weight: 700;
            font-size: 20px;
            line-height: 33px;
            letter-spacing: 0.05em;
            color: $color-dark;
            text-transform: uppercase;

            &:after {
                content: "Menu";
                font-family: $font-family;
                font-weight: 700;
                font-size: 20px;
                line-height: 33px;
            }
        }
    
        &__svg-container {
            position: relative;
            display: block;
            height: 3px;
            width: 24px;
            top: 14px;
            background-color: $color-second;
    
            &:before, 
            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                height: 100%;
                width: 24px;
                background-color: $color-second;
                transition: all $duration ease-out;
            }
    
            &:before {
                top: 7px;
            }
    
            &:after {
                top: -7px;
            } 
        }
    }
}


// Responsive

// 1200
@media screen and (max-width: $large) {

    .nav-main {

        &__container {
            &:before {
                width: calc(50% - 150px);
            }
        }

        &__logo {
            top: 70px;
            gap: 8px;
            
            svg {
                height: 53px;
                width: 51px;
            }

            > div {
                line-height: 46px;
                font-size: 44px;

                span {
                    line-height: 19px;
                    font-size: 13px;
                }
            }
        }

        .menu__nav__list {
            width: 300px;
            margin-top: 200px;

            .nav-main-item {

                .nav-main-link {
                    font-size: 26px;
                    margin-bottom: 35px
                }

                .sub-menu {
                    left: 400px;
                    width: 250px;

                    .nav-drop {
                        columns: 250px 2;
                        gap: 10px 40px;
                        max-height: 540px;
                    }
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .nav-main {

        &__logo {
            display: none;
        }

        &__container {
            background: $color-third;
            overflow: hidden auto;
            
            &:before {
                content: unset;
            }

            .container {
                &:before, &:after {
                    content: unset;
                }
            }
        }

        .menu__nav__list {
            margin: 120px 0 0;
            padding-top: 15px;
            width: 100%;

            .nav-main-item {
                .nav-main-link {
                    align-items: center;
                    display: flex;
                    width: auto;
                    font-size: 24px;
                    line-height: 30px;

                    &:before {
                        content: unset;
                    }

                    &:hover {
                        color: $color-dark;
                    }
                }

                .sub-menu {
                    z-index: 1;
                    display: block;
                    height: 100%;
                    width: 100%;
                    left: 100vw;
                    background: $color-third;
                    margin-top: 10px;
                    transition: all .5s ease-in-out;

                    .nav-drop__title {
                        display: flex;
                        position: relative;
                        top: unset;
                        left: unset;
                        opacity: 1;
                        width: 100%;
                        height: auto;
                        text-align: left;
                        transform: unset;
                        color: $color-second;
                        margin-bottom: 30px;
                        cursor: pointer;
                        gap: 15px;
                        font-family: $font-family;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 30px;
                        color: $color-dark;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;


                        svg {
                            fill: $color-dark;
                            background: $color-white;
                            border-radius: 50px;
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .nav-drop {
                        gap: unset;
                        columns: unset;
                        max-height: unset;
                        width: 100%;
                        // overflow: auto;
                        height: 67vh;
                        flex-wrap: nowrap;

                        li {
                            height: auto;
                            padding-bottom: 25px;
                            
                            a {
                                padding: 0;
                            }
                        }
                    }
                }

                &.active {
                    .nav-main-link {
                        padding: 0;
                        color: $color-second;
                    }

                    .sub-menu {
                        left: -15px;
                    }
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .nav-main {

        &__button {
            &:checked {
                & ~ .nav-main__icon {
                    position: fixed;
                    left: calc(50% + 95px);
                    
                    .nav-main__icon--container {

                        &:after {
                            left: 33px;
                            top: -10px;
                        }

                        .nav-main__icon__svg-container {
                            left: 5px;
                            top: -60px;
                        }
                    }
                }
            }
        }

        &__icon {
            height: auto;

            &--container {
                flex-direction: column;
                align-items: center;
                gap: 14px;
                height: 36px;
                justify-content: flex-end;

                &:after {
                    font-size: 10px;
                    line-height: 10px;
                    letter-spacing: 0.05em;
                }
            }

            &__svg-container {
                width: 30px;
                height: 3px;
                top: 0;

                &:before {
                    width: 30px;
                    top: 8px;
                }

                &:after {
                    width: 30px;
                    top: -8px;
                }
            }
        }

        .menu__nav__list {
            margin: 65px 0 0;

            .nav-main-item {
                &.active {
                    & .sub-menu {
                        left: 0;
                    }
                }

                .nav-main-link {
                    margin-bottom: 30px;
                }
            }
        }
    }
}