.header {
    position: relative;

    &--fixed {
        z-index: 20;

        .header-top {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 0;
            height: 80px;
            box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
            transition: all 0.25s ease-in-out;

            &__container {
                align-items: center;
            }

            &__logo {
                height: 46.15px;
            }

            &__title {
                align-items: center;
                gap: 10px;
                
                svg {
                    width: 44.12px;
                    height: 46.15px;
                }

                > div {
                    font-size: 39px;
                    line-height: 39px;
                    span {
                        display: none;
                    }
                }
            }

            &__content {
                margin-bottom: 0;
            }
        }
    }

}

.header-top {
    height: 151px;
    background: $color-white;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 100%;

        > a {
            &:hover {
                color: $color-dark;
            }
        }
    }

    &__logo {
        display: flex;
    }

    &__title {
        display: flex;
        gap: 19px;
        margin: 0;
        margin: 0 0 -17px;

        div {
            font-family: $font-family;
            font-weight: 700;
            font-size: 85px;
            line-height: 85px;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            gap: 4px;
            transition: all $duration ease-in-out;

            span {
                font-weight: 100;
                font-size: 25px;
                line-height: 30px;
                margin-left: 4px;
            }
        }
    }

    &__titre {
        font-size: 0;
        line-height: 0;
        margin: 0;
    }

    &__content {
        display: flex;
        align-items: flex-end;
        margin-bottom: 52px;
        gap: 35px;
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    display: flex;
    gap: 10px;
    height: 40px;
    
    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }

    .tool {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: $color-dark;
        margin: 0;
        padding: 0;
        border: none;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-white;
            width: 100%;
            height: 100%;
            transition: all $duration ease-in-out;
        }

        &:hover {
            background-color: $color-main;
            
            svg {
                fill: $color-dark;
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $color-second;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header-top {
        height: 140px;

        &__container {
            align-items: center;
        }

        &__title {
            gap: 10px;
            margin: 0 0 -21px;

            svg {
                width: 70px;
                height: 73px;
            }

            > div {
                gap: 1px;
                font-size: 60px;
                line-height: 60px;

                span {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }

        &__content {
            margin-bottom: 0;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

}


// 960
@media screen and (max-width: $medium) {

    .header-top {
        height: 120px;

        &__container {
            align-items: flex-end;
        }

        &__title {
            margin: 0;
        }

        &__content {
            margin-bottom: 13px;
            gap: 20px;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        height: 100px;

        &__title {
            flex-direction: column;
            position: relative;
            gap: 5px;

            svg {
                width: 36px;
                height: 38px;
            }

            > div {
                font-size: 32px;
                line-height: 32px;

                span {
                    font-size: 12px;
                    line-height: 12px;
                    position: absolute;
                    top: 28px;
                    left: 34px;
                }
            }
        }

        &__content {
            gap: 15px;
            margin-bottom: -5px;
            align-items: flex-start;
        }
    }
    
    .tools {
        gap: 8px;

        .tool {
            width: 35px;
            height: 35px;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }
}
