.breadcrumb {
    margin-top: 35px;

    ul  {
        display: flex;
        flex-wrap: wrap;
        gap: 0px 30px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-dark;
        position: relative;

        &:not(:last-child) {
            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/ic-nav-chevron-bottom.svg);
                background-color: $color-dark;
                mask-repeat: no-repeat;
                top: unset;
                right: -25px;
                width: 22px;
                height: 22px;
                -webkit-mask-size: 22px;
                mask-size: 22px;
                transform: rotate(-90deg);
            }
        }

        &:first-child {
            padding-left: 0;
        }

        a {
            font-family: $font-family--heading;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            
            &:hover {
                text-decoration: underline;
                color: $color-dark;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
