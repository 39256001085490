.search-container {
    display: flex;
    position: relative;
    align-items: center;

    .searchInput {
        padding: 24px 40px 24px 24px;
        border: 1px solid $color-gray;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: $color-white;
        z-index: 2;
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .search-svg {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 23px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-main;
        border-radius: 50%;
        cursor: pointer;
        z-index: 3;
        transition: all $duration;

        svg {
            width: 15px;
            height: 15px;
            fill: $color-dark;
            transition: all $duration;
        }

        &:hover {
            background-color: $color-second;
            svg {
                fill: $color-white;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: -2px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    padding-top: 10px;
    margin-top: -8px;

    li {
        position: relative;
        background: $color-white;
        border-bottom: 1px solid $color-bg--neutral;

        &:first-child {
            border-top: 1px solid $color-dark;
        }

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-gray;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-gray;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-main;
                color: $color-dark;

                &::after {
                    color: $color-dark;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-dark;
                }
            }
        }

        &:last-child {
            border-bottom:0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-main;
                color: $color-dark;

                &::after {
                    color: $color-dark;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 70px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $color-third;
    transition: all 0.65s;

    &:before {
        position: absolute;
        content: " ";
        width: 100%;
        height: 280px;
        left: 0;
        top: 23.5%;
        background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/vague_recherche.svg);
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
    }

    .container {
        position: relative;
        height: 100%;
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 25px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family;
            font-weight: 700;
            font-size: 50px;
            line-height: 55px;
            color: $color-dark;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            .searchInput {
                background: $color-white;
                border-color: $color-white;
                font-family: $font-family--heading;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: $color-dark;

                &::placeholder {
                    font-family: $font-family--heading;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: $color-dark;
                }
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        background: $color-dark;
        border-radius: 50%;
        transition: all $duration;
        
        svg {
            width: 21px;
            height: 21px;
            fill: $color-white;
            transition: all $duration;
        }

        &:hover {
            background: $color-main;
            svg {
                fill: $color-dark;
            }
        }
    }
}


//======================================================================================================
// Search Page
//======================================================================================================
.filter {
    .search-container {
        .search-svg {
            background: $color-main;
            border-radius: 50px;
            transition: all $duration;

            &:hover {
                background: $color-second;

                svg {
                    fill: $color-white;
                }
            }

            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        font-size: 2.5em;
    }

}
