.footer {
    margin-top: 16px;

    &__infos {
        display: flex;
    }

    &__coordonnees {
        margin-left: 74px;
        width: 173px;
    }

    &__horaires {
        margin-left: 104px;
        width: 405px;
    }

    &__question {
        margin-left: 91px;
        text-align: center;
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 200px;
            height: 200px;
            right: -82px;
            bottom: -108px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/motif_gris.png);
            border-radius: 500px;
            z-index: 0;
        }
    }

    &__title {
        font-family: $font-family;
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
    }

    &__text {
        font-family: $font-family--heading;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-top: 15px;
    }

    &__phone {
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        margin-top: 14px;
        transition: all $duration ease-in-out;

        svg {
            width: 24px;
            height: 24px;
            fill: $color-dark;
            transition: all $duration ease-in-out;
        }

        &:hover {
            color: $color-second;

            svg {
                fill: $color-second;
            }
        }
    }
    
    &__button {
        background-color: $color-main;
        color: $color-dark;
        padding: 21.5px 30px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 14px;
        padding: 17px 30px;
        margin-top: 17px;
        text-transform: uppercase;
        z-index: 2;
        position: relative;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-dark;
            width: 26px;
            height: 26px;
            transition: all $duration ease-in-out;
        }

        &:hover {
            background-color: $color-second;
            color: $color-white;
            
            svg {
                fill: $color-white;
            }
        }
    }

    &__menu {
        padding: 80px 0 30px;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;
            padding: 0 30px;
            font-family: $font-family--heading;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;

            &:first-child {
                padding-left: 0;
            }

            a {
                &:hover {
                        color: $color-second;
                }
            } 

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 10px;
                background: $color-text;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }
}

body:not(.home) {
    .footer {
        background: $color-third;
        margin-top: 170px;
        position: relative;
        
        &:before {
            position: absolute;
            content: " ";
            width: 100%;
            height: 180px;
            left: 0;
            top: -180px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/vague_footer_gris.svg);
            background-position: center;
            background-repeat: no-repeat;
            z-index: -1;
        }

        &__question {
            &:before {
                background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/motif_blanc.png);
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        &__logo {
            svg {
                width: 70px;
                height: 93.33px;
            }
        }

        &__coordonnees {
            margin-left: 40px;
            width: 173px;
        }

        &__horaires {
            margin-left: 60px;
            width: 320px;
        }

        &__question {
            margin-left: 55px;

            &:before {
                width: 150px;
                height: 150px;
                right: -95px;
                bottom: -75px;
            }
        }

        &__text {
            margin-top: 12px;
        }

        &__button {
            margin-top: 12px;
        }

        &__menu {
            padding: 68px 0 30px;

            li {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        &__infos {
            flex-direction: column;
            align-items: center;
        }

        &__logo {
            margin-left: 20px;
        }

        &__coordonnees {
            margin-left: 0;
            margin-top: 18px;
        }

        &__horaires {
            margin-left: 0;
            margin-top: 38px;
            width: 483px; 
        }

        &__question {
            margin-top: 30px;
            margin-left: 0;

            &:before {
                bottom: -50px;
                right: -45px;
                z-index: -1;
            }
        }
        
        &__title {
            text-align: center;
        }

        &__text {
            margin-top: 9px;
            text-align: center;
        }

        &__phone {
            margin-top: 5px;
        }

        &__button {
            margin-top: 15px;
        }

        &__menu {
            padding: 90px 0 30px;
    
            ul {
                gap: 20px 0;
                justify-content: center;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {


    .footer {
        margin-top: 20px;

        &__coordonnees {
            margin-top: 15px;
        }

        &__horaires {
            width: 300px;
        }

        &__question {
            margin-top: 40px;
        }

        &__menu {
            padding: 59px 0 30px;

            ul {
                gap: 20px 30px;
            }

            li {
                padding: 0;

                &:before {
                    display: none;
                }
            }
        }
    }
}