.front-page {
    position: relative;

    h2, h3, h4 {
        &:before {
            display: none;
        }
    }

    &__header {
        > div {
            display: flex;
            align-items: flex-start;
            gap: 24px;
        }
    }

    &__title {
        font-family: $font-family;
        font-weight: 100;
        font-size: 30px;
        line-height: 30px;
        color: $color-dark;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: lowercase;
        padding: 0;
        margin: 0;
        margin-left: 11px;

        span {
            width: 10px;
            height: 10px;
            border-radius: 50px;
            background-color: $color-main;
        }
    }

    &__description {
        font-family: $font-family;
        font-weight: 700;
        font-size: 50px;
        line-height: 55px;
        color: $color-dark;
        margin-top: -4px;
    }

    &__navigation {
        height: 40px;
        display: flex;
        gap: 35px;
        margin-top: 3px;

        &__prev,
        &__next {
            position: relative!important;
            width: 40px!important;
            height: 40px!important;
            margin: 0;
            padding: 0;
            background-color: $color-third;
            border: 2px solid $color-white;
            border-radius: 50px;
            transition: all $duration ease;

            &::after {
                position: relative;
                content: ""!important;
                top: 0;
                width: 40px;
                min-width: 40px;
                height: 40px;
                mask-repeat: no-repeat;
                mask-size: 40px;
                background-color: $color-dark;
                transition: all $duration ease;
            }

            &:hover {
                &:hover {
                    background-color: $color-main;
                    border-color: $color-main;
                }
            }
        }

        &__prev {
            &::after {
                mask-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/Pictos/nav_gauche.svg);
            }
        }

        &__next {
            &::after {
                mask-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/Pictos/nav_droite.svg);
            }
        }
    }

    &__button {
        background-color: $color-main;
        color: $color-dark;
        padding: 21.5px 30px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 14px;
        padding: 17px 30px;
        text-transform: uppercase;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-dark;
            width: 26px;
            height: 26px;
            transition: all $duration ease-in-out;
        }
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &:before {
        position: absolute;
        content: " ";
        width: 100%;
        height: 300px;
        left: 0;
        bottom: 100px;
        background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/vague_fond_gris.svg);
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
    }

    > .container {
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 200px;
            height: 200px;
            left: -110px;
            top: 340px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/motif_gris.png);
            border-radius: 500px;
            z-index: -1;
        }
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .slideshow {
        width: 100%;
        overflow: initial;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: flex;
            flex-direction: row-reverse;
            position: relative;

            &--image, &--video {
                margin-top: -10px;
                position: relative;

                &:before {
                    position: absolute;
                    content: " ";
                    width: 58px;
                    height: 73px;
                    left: -20px;
                    bottom: 146px;
                    background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/bandes_vertes.svg);
                    background-repeat: no-repeat;
                    z-index: 0;
                }
            }

            
        }

        .swiper-slide {
            transition: opacity $duration ease-in-out;
        }

        .swiper-slide-active {
            z-index: 1;
        }

        &__title {
            color: $color-dark;
            font-family: $font-family;
            font-weight: 400;
            font-size: 38px;
            line-height: 42px;
            position: absolute;
            left: 0;
            max-width: 370px;
            bottom: 308px;
        }

        .shape-container--img {
            display: none;
        }

        // Navigation (arrow)
        &__navigation {
            position: absolute;
            left: 0;
            top: unset;
            bottom: 249px;
            height: 40px;
            display: flex;
            gap: 25px;
    
            &__btn {
                pointer-events: auto;
                top: unset;
                left: unset;
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 0;
                background: $color-white;
                border: 2px solid $color-third;
                border-radius: 50px;
                position: relative;
                transition: all $duration ease-in-out;

                &--prev, &--next {

                    &::after {
                        position: relative;
                        content: "";
                        top: 0;
                        width: 40px;
                        min-width: 40px;
                        height: 40px;
                        mask-repeat: no-repeat;
                        mask-size: 40px;
                        background-color: $color-dark;
                        transition: all $duration ease;
                    }
                }

                &--prev {
                    &::after {
                        mask-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/Pictos/nav_gauche.svg);
                    }
                }

                &--next {
                    &::after {
                        mask-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/Pictos/nav_droite.svg);
    
                    }
                }

                &:hover {
                    background-color: $color-main;
                    border-color: $color-main;
                }
            }
        }

    
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    background-color: $color-third;
    padding-top: 82px;
    margin-top: -100px;

    &__swiper {
        overflow: visible!important;

        &:before {
            position: absolute;
            content: " ";
            width: 58px;
            height: 73px;
            left: -25px;
            top: -30px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/bandes_vertes.svg);
            background-repeat: no-repeat;
            z-index: 2;
        }

        &:after {
            position: absolute;
            content: " ";
            width: 200px;
            height: 200px;
            right: -100px;
            top: -75px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/motif_blanc.png);
            border-radius: 500px;
            z-index: -1;
        }
    }

    .swiper-slide {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s ease-in-out;
        
        &-active, &-prev, &-next {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__list {
        margin-top: 40px;
        overflow: visible;
    }

    &__item {
        width: 370px;
        height: 490px !important;
    }

    &__link {
        &:hover {
            .news__image {
                box-shadow: none;
            }

            .news__title--small {
                margin: 20px 0 6px;
            }
        }
    }

    &__image {
        position: relative;
        width: 370px;
        height: 328px;
        border-radius: 10px;
        box-shadow: 135px 135px 98px rgba(0, 0, 0, 0.02), 76px 76px 82px rgba(0, 0, 0, 0.05), 34px 34px 61px rgba(0, 0, 0, 0.05), 8px 8px 34px rgba(0, 0, 0, 0.05), 5px 3px 15px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        transition: all $duration ease-in-out;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-gray;
            background-position: center;
            width: 370px;
            height: 328px;
        }
    }

    &__title--small {
        color: $color-dark;
        font-family: $font-family;
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        margin: 30px 0 6px;
        transition: all $duration ease-in-out;
    }

    &__introduction {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }

    &__buttons {
        margin-top: 60px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 2;
    }

    &__button {
        &:hover {
            background-color: $color-second;
            color: $color-white;
            
            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Événements 
//======================================================================================================

.home-events {
    background-color: $color-third;
    padding-top: 9px;
    padding-bottom: 225px;
    position: relative;

    &:before {
        position: absolute;
        content: " ";
        width: 100%;
        height: 300px;
        left: 0;
        bottom: 0;
        background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/vague_fond_blanc.svg);
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
    }

    &__title {
        span {
            background-color: $color-second;
        }
    }

    &__swiper {
        overflow: visible!important;
        margin-top: 40px;

        &:before {
            position: absolute;
            content: " ";
            width: 58px;
            height: 73px;
            right: -29px;
            top: 8px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/bandes_vertes.svg);
            background-repeat: no-repeat;
            z-index: 2;
        }

        &:after {
            position: absolute;
            content: " ";
            width: 200px;
            height: 200px;
            left: -80px;
            bottom: -100px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/motif_blanc.png);
            border-radius: 500px;
            z-index: -1;
        }
    }

    .swiper-slide {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s ease-in-out;
        
        &-active, &-next {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__link {
       

        &:hover {
            .home-events__content {
                box-shadow: none;
            }
            
            .home-events__date {
                &:after {
                    width: 10px;
                    right: 1px;
                }
            }
        }
    }

    &__content {
        overflow: hidden;
        display: flex;
        height: 180px;
        background-color: $color-white;
        border-radius: 10px;
        box-shadow: 135px 135px 98px rgba(0, 0, 0, 0.02), 76px 76px 82px rgba(0, 0, 0, 0.05), 34px 34px 61px rgba(0, 0, 0, 0.05), 8px 8px 34px rgba(0, 0, 0, 0.05), 5px 3px 15px rgba(0, 0, 0, 0.05);
        transition: all 0.5s ease-in-out;
    }

    &__date {
        min-width: 70px;
        padding: 19px 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 0;

        &:before {
            position: absolute;
            content: " ";
            width: 120px;
            height: 100%;
            left: 0;
            top: 0;
            clip-path: polygon(0 0, 83% 0, 100% 100%, 0% 100%);
            background: $color-dark;
            border-radius: 10px 0 0 10px;
            z-index: -1;
        }

        &:after {
            position: absolute;
            content: " ";
            width: 5px;
            height: 184px;
            right: 6px;
            top: -2px;
            transform: rotate(173.5deg);
            clip-path: polygon(0% 0%, 100% 0.5%, 100% 100%, 0% 99.5%);
            background: $color-second;
            transition: all $duration ease-in-out;
        }

        p {
            display: flex;
            flex-direction: column;
            font-family: $font-family;
            color: $color-white;
        }

        svg {
            fill: $color-main;
            width: 26px;
            height: 26px;
        }

        &__day {
            font-weight: 700;
            font-size: 34px;
            line-height: 34px;
        }

        &__month {
            font-weight: 800;
            font-size: 24px;
            line-height: 24px;
        }
    }

    &__time {
        display: flex;
        gap: 4px;
        font-family: $font-family--heading;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        
        svg {
            width: 26px;
            height: 26px;
        }
    }

    &__infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 50px 0 30px;
        gap: 16px;
    }

    &__title--small {
        font-family: $font-family;
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        color: $color-dark;
        margin: 0;
        transition: all $duration ease-in-out;
    }

    &__buttons {
        margin-top: 60px;
        display: flex;
        justify-content: flex-end;
    }

    &__button {
        background-color: $color-second;
        color: $color-white;

        svg {
            fill: $color-white;
        }

        &:hover {
            background-color: $color-main;
            color: $color-dark;
            
            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// En un clic
//======================================================================================================

.enunclic {
    margin-top: -28px;
    padding-bottom: 293px;
    z-index: 1;
    position: relative;

    &:before {
        position: absolute;
        content: " ";
        width: 100%;
        height: 300px;
        left: 0;
        bottom: 0;
        background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/vague_fond_gris.svg);
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
    }

    &__content {
        display: flex;
        grid-gap: 30px;
        margin-top: 20px;
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 58px;
            height: 73px;
            left: -29px;
            top: 29px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/bandes_vertes.svg);
            background-repeat: no-repeat;
            z-index: 2;
        }

        &:after {
            position: absolute;
            content: " ";
            width: 200px;
            height: 200px;
            right: -80px;
            top: 230px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/motif_gris.png);
            border-radius: 500px;
            z-index: -1;
        }
    }

    &__one {
        grid-column: 1;
        grid-row: 1;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    &__four {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    &__two {
        grid-column: 2;
        grid-row: 1;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
    }

    &__three {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
    }

    &__five {
        grid-column: 1 / span 3;
        grid-row: 1;
    }

    &__six {
        grid-column: 4;
        grid-row: 1;
    }

    &__seven {
        grid-column: 1 / span 2;
        grid-row: 2 / span 2;
    }

    &__eight {
        grid-column: 3 / span 2;
        grid-row: 2;
    }

    &__nine {
        grid-column: 3 / span 2;
        grid-row: 3;
        display: flex;
        flex-direction: row;
        grid-gap: 30px;
    }
    
    &__image {
        background: rgba(0,0,0,0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        &:before {
            position: absolute;
            content: " ";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;    
            border-radius: 10px;
            z-index: 0;
            background-position: center;
            background-repeat: no-repeat;
        }

        &:after {
            position: absolute;
            content: " ";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
            border-radius: 10px;
            z-index: 0;
        }

        p {
            margin: 10px 0;
            font-family: $font-family;
            font-weight: 700;
            font-size: 28px;
            line-height: 30px;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
            color: $color-white;
            z-index: 1;
            text-align: center;
            transition: all $duration ease-in-out;
        }

        svg {
            z-index: 1;
            &:last-child {
                width: 26px;
                height: 26px;
                transform: rotate(180deg);
                fill: $color-white;
            }
        }

        &:hover {
            p {
                margin: 0;
            }
        }
    }

    &__link {
        width: 370px;
        height: 110px;
        background-color: $color-third;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 25px;
        border: 1px solid $color-third;
        transition: all $duration ease-in-out;

        p {
            font-family: $font-family;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $color-dark;
            text-transform: uppercase;
            text-align: center;
        }

        svg:last-child {
            width: 26px;
            height: 26px;
            transform: rotate(-90deg);
            fill: $color-dark;
            margin-right: -5px;
        }

        &:hover {
            background-color: transparent;
        }

        &--green {
            background-color: $color-main;
            border: 1px solid $color-main;
            transition: all $duration ease-in-out;

            &:hover {
                background-color: transparent;

                .svg-green {
                    fill: $color-main;
                }
            }
        }
    }

    &__icon {
        width: 170px;
        height: 160px;
        background-color: $color-third;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 25px 0;
        gap: 10px;
        border-radius: 10px;
        border: 1px solid $color-third;
        transition: all $duration ease-in-out;

        p {
            font-family: $font-family;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $color-dark;
            text-transform: uppercase;
            text-align: center;
            height: 45px;
            display: flex;
            align-items: center;
        }

        svg:first-child {
            width: 60px;
            height: 60px;
        }

        &:hover {
            background-color: transparent;
        }
    }

    &__portail {
        width: 370px;
        height: 395px;

        &:before {
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/ar_famille.jpg);
        }
    }

    &__association {
        width: 570px;
        height: 340px;
        &:before {
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/ar_associations.jpg);
        }
    }

    &__demarche {
        width: 370px;
        height: 295px;

        &:before {
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/ar_demarches.jpg);
        }
    }
}

//======================================================================================================
// Notre commune
//======================================================================================================

.notre-commune {
    background-color: $color-third;
    position: relative;
    z-index: 1;
    padding-bottom: 190px;

    &:before {
        position: absolute;
        content: " ";
        width: 100%;
        height: 180px;
        left: 0;
        bottom: 0;
        background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/vague_footer.svg);
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
    }
    
    &__bloc {
        display: flex;
        justify-content: space-between;
        gap: 125px;
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 200px;
            height: 200px;
            left: 0;
            bottom: -5px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/carte_situation.svg);
            border-radius: 500px;
            z-index: 1;
        }
    }

    &__wrapper {
        margin-top: -160px;
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 200px;
            height: 200px;
            right: -80px;
            top: 175px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/motif_blanc.png);
            border-radius: 500px;
            z-index: -1;
        }

        &:after {
            position: absolute;
            content: " ";
            width: 58px;
            height: 73px;
            right: -38px;
            bottom: 125px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/bandes_vertes.svg);
            background-repeat: no-repeat;
            z-index: 1;
        }
    }

    &__content {
        margin-top: 16px;
    }

    &__title {
        text-transform: unset;

        span {
            background-color: $color-second;
        }
    }

    &__text {
        font-family: $font-family;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-top: 16px;
    }
}

//======================================================================================================
// Chiffres commune
//======================================================================================================

.chiffres-commune {
    margin-top: 50px;

    &__blocs {
        display: flex;
        gap: 55px;
    }

    &__bloc {
        > a {
            &:hover {
                .chiffres-commune__title {
                    color: $color-dark;
    
                    &:before {
                        width: 12px;
                        height: 12px;
                        background-color: $color-main;
                    }
                }
            }
        }
    }

    &__title {
        font-family: $font-family;
        font-weight: 700;
        font-size: 38px;
        line-height: 42px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 26px;
        position: relative;
        transition: all $duration ease-in-out;

        &:before {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50px;
            background-color: $color-second;
            top: 18px;
            left: -20px;
            transition: all $duration ease-in-out;
        }
    }

    &__text {
        font-family: $font-family;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home-slideshow {
        &:before {
            background-size: 1500px;
            width: 1500px;
            bottom: 97px;
            height: 215px;
            left: calc(50% - 750px);
        }

        > .container {
            &:before {
                top: 255px;
                width: 150px;
                height: 150px;
                left: -80px;
            }
        }

        .slideshow {
            &__container--image, &__container--video {
                margin-top: 0;

                &:before {
                    bottom: 119px;
                }

                > svg {
                    width: 600px;
                    height: 430px;
                }
            }

            &__title {
                font-size: 30px;
                line-height: 36px;
                max-width: 295px;
                margin-bottom: -40px;
            }

            &__navigation {
                bottom: 208px;
            }
        }
    }

    .news {
        &__swiper {
            &:after {
                width: 150px;
                height: 150px;
                right: -60px;
                top: -50px;
            }
        }

        &__item {
            width: 300px;
        }

        &__link {
            &:hover {
                .news__title--small {
                    margin: 10px 0 6px;
                }
            }
        }

        &__image {
            width: 300px;
            height: 265px;
    
            &--notimg {
                width: 300px;
                height: 265px;
            }
        }

        &__title--small {
            margin: 20px 0 6px;
        }
    }

    .home-events {
        padding-top: 60px;
        padding-bottom: 159px;

        &:before {
            background-size: 1500px;
            width: 1500px;
            bottom: -1px;
            height: 215px;
            left: calc(50% - 750px);
        }

        &__swiper {
            &:before {
                right: -45px;
                top: 90px;
            }

            &:after {
                width: 150px;
                height: 150px;
                left: -64px;
                bottom: -71px;
            }
        }

        &__infos {
            margin: 0 30px 0 30px;
        }
    }

    .enunclic {
        margin-top: 0px;
        padding-bottom: 225px;

        &:before {
            background-size: 1500px;
            width: 1500px;
            bottom: -1px;
            height: 200px;
            left: calc(50% - 750px);
        }

        &__content, &__one, &__two, &__nine {
            grid-gap: 20px;
        }

        &__content {
            &:before {
                top: 36px;
                left: -40px;
            }

            &:after {
                width: 150px;
                height: 150px;
                top: 206px;
                right: -75px;
            }
        }

        &__portail {
            width: 300px;
            height: 320px;

            &:before {
                width: 300px;
                height: 320px;
                background-size: 300px;
            }
        }

        &__association {
            width: 460px;
            height: 280px;

            &:before {
                width: 460px;
                height: 280px;
                background-size: 460px;
            }
        }

        &__demarche {
            width: 300px;
            height: 240px;

            &:before {
                width: 300px;
                height: 240px;
                background-size: 300px;
            }
        }

        &__link {
            width: 300px;
            height: 90px;
            padding: 20px;
            justify-content: unset;

            svg:first-child {
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }

            p {
                text-align: start;
                margin-right: 15px;
                width: 150px;
            }
        }

        &__icon {
            width: 140px;
            height: 130px;
            gap: 0;

            svg:first-child {
                width: 50px;
                height: 50px;
            }
        }

        &__image {
            svg:first-child {
                width: 100px;
                height: 95px;
                margin-top: 12px;
            }
        }
    }

    .notre-commune {
        padding-bottom: 145px;

        &:before {
            background-size: 1500px;
            width: 1500px;
            bottom: -1px;
            height: 130px;
            left: calc(50% - 750px);
        }
        
        &__wrapper {
            margin-top: -123px;

            svg {
                width: 437px;
                height: 450px;
            }

            &:before {
                width: 150px;
                height: 150px;
                right: -52px;
                top: 153px;
            }

            &:after {
                width: 48.39px;
                height: 61.35px;
                background-size: 48.39px;
                right: -33px;
                bottom: 116px;
            }
        }

        &__bloc {
            gap: 63px;

            &:before {
                width: 160px;
                height: 160px;
                background-size: 160px;
                bottom: 13px;
            }
        }

        &__content {
            margin-top: -7px;
            max-width: 440px;
        }

        .chiffres-commune {
            margin-top: 25px;

            &__blocs {
                gap: 45px;
            }

            &__text {
                font-size: 24px;
                line-height: 34px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .home-slideshow {
        position: relative;

        &:before {
            background-size: 1200px;
            bottom: 206px;
            height: 172px;
            left: calc(50% - 600px);
            width: 1200px;
        }

        > .container {
            &:before {
                display: none;
            }
        }

        .slideshow {
            &__container {
                flex-direction: column;

                &--image, &--video {
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;

                    &:before {
                        bottom: 36px;
                        left: 0;
                    }
                }
            }

            &__title {
                position: relative;
                max-width: 100%;
                margin-bottom: 0;
                margin-top: 35px;
                bottom: unset;
                left: unset;
            }

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__navigation {
                bottom: 46px;

                &__btn {
                    background: $color-third;
                    border-color: $color-white;
                }
            }

            .shape-container--video {
                display: none;
            }

            .shape-container--img {
                display: block;
                svg {
                    width: 600px;
                    height: 430px;
                    margin-top: 20px;
                    margin-left: 10px;
                }
            }
        }
    }

    .news {
        padding-top: 285px;
        margin-top: -207px;
        
        &__swiper {
            &:after {
                right: -75px;
                top: -68px;
            }
        }

        .swiper-slide-prev {
            opacity: 0;
            pointer-events: none;
        }
    }

    .home-events {
        padding-bottom: 143px;

        &:before {
            background-size: 1200px;
            height: 172px;
            left: calc(50% - 600px);
            width: 1200px;
        }

        &__swiper {
            &:before {
                right: -40px;
                top: 210px;
            }
        }

        &__content {
            flex-direction: column;
            height: 300px;
        }

        &__link {
            &:hover {
                .home-events__date {
                    &:after {
                        height: 10px;
                        width: 100%;
                        right: 0;
                    }
                }
            }
        }
        
        &__date {
            flex-direction: row;
            justify-content: flex-start;
            padding: 26px 25px 38px;

            &:before {
                clip-path: polygon(0 0,100% 0,100% 83%,0 100%);
                width: 100%;
                border-radius: 10px 10px 0 0;
            }

            &:after {
                width: 100%;
                height: 5px;
                top: 88px;
                right: 0;
                transform: rotate(-3deg);
            }

            svg {
                transform: rotate(-90deg);
                margin: 4px 11px 0;
            }

            p {
                flex-direction: row;
                align-items: end;
                gap: 5px;
            }
        }

        &__infos {
            margin: 0 20px;
            height: 100%;
        }
    }

    .enunclic {
        padding-bottom: 275px;

        &:before {
            background-size: 1200px;
            height: 164px;
            left: calc(50% - 600px);
            width: 1200px;
        }

        &__content {
            &:after {
                top: 125px;
            }
        }

        &__portail, &__association, &__demarche {
            width: 300px;
            height: 190px;

            &:before {
                background-size: 315px;
                width: 315px;
                height: 190px;
            }

            svg:first-child {
                width: 80px;
                height: 76px;
                margin-top: 0;
            }

            p {
                margin: 0;
            }
        }

        &__demarche {
            margin-top: -5px;
        }
    
        &__content {
           flex-direction: column;

            &:before {
                display: none;
            }
        }
    
        &__one {
            flex-direction: row;
        }

        &__four {
            row-gap: 10px;
        }

        &__two {
            grid-row: 6;
        }

        &__five {
            grid-column: 3 / span 2;
            grid-row: 1 / span 3;
        }

        &__seven {
            grid-column: 3 / span 2;
            grid-row: 4 / span 3;
        }
    
        &__six {
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;
            flex-direction: row;
        }

        &__eight, &__nine {
            grid-column: 1 / span 2;
        }

        &__eight {
            grid-row: 3 / span 2;
        }

        &__nine {
            grid-row: 5 / span 2;
        }
    }

    .notre-commune {
        padding-bottom: 154px;
        
        &:before {
            background-size: 1200px;
            height: 104px;
            left: calc(50% - 600px);
            width: 1200px;
        }

        &__bloc {
            flex-direction: column;
            gap: 27px;

            &:before {
                top: 83px;
            }
        }

        &__wrapper {
            margin-top: -200px;
            width: fit-content;
        }

        &__content {
            margin-top: 0;
            max-width: 100%;
        }

        &__text {
            width: 610px;
        }

        .chiffres-commune {
            margin-top: 17px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .front-page {
        &__header {
            display: flex;
            flex-direction: column;
            gap: 5px;

            > div {
                gap: 5px;
            }
        }

        &__description {
            font-size: 44px;
            line-height: 45px;
        }

        &__navigation {
            margin-top: 0;
            gap: 30px;
        }
    }

    .home-slideshow {

        &:before {
            background-size: 960px;
            width: 960px;
            height: 138px;
            left: calc(50% - 960px/2);
            bottom: 107px;
        }

        .slideshow {
            &__container {
                &--image, &--video {
                    margin-top: 30px;

                    svg {
                        width: 320px;
                        height: 230px;
                        min-width: 320px;
                    }

                    &:before {
                        display: none;
                    }
                }
            }

            &__title {
                font-size: 22px;
                line-height: 28px;
                margin-top: 56px;
            }

            &__navigation {
                top: 265px;
                bottom: unset;
                gap: 20px;
            }

            .shape-container--img {
                svg {
                    width: 320px;
                    height: 230px;
                    margin-left: -10px;
                    margin-top: 30px;
                }
            }
        }
    }

    .news {
        padding-top: 167px;
        margin-top: -108px;

        &__swiper {
            &:after {
                right: -85px;
                top: -18px;
            }
        }

        .swiper-slide-next {
            opacity: 0;
            pointer-events: none;
        }

        &__buttons {
            margin-top: 42px;
            justify-content: flex-start;
        }
    }

    .home-events {
        padding-top: 84px;
        padding-bottom: 134px;

        &:before {
            background-size: 960px;
            width: 960px;
            height: 138px;
            left: calc(50% - 960px/2);
        }

        &__header {
            > div {
                gap: 50px;
            }
        }

        &__swiper {
            &:after {
                bottom: -68px;
                left: -86px;
            }
        }

        .swiper-slide-next {
            opacity: 0;
            pointer-events: none;
        }
    }

    .enunclic {
        padding-top: 27px;
        padding-bottom: 207px;

        &:before {
            background-size: 960px;
            width: 960px;
            height: 138px;
            left: calc(50% - 960px/2);
        }

        &__content {
            &:after {
                top: 370px;
            }
        }

        &__demarche {
            margin-top: 0;
        }

        &__one {
            flex-direction: column;
        }

        &__four {
            row-gap: 20px;
        }

        &__two {
            display: flex;
            flex-direction: column;
        }

        &__seven {
            order: 2;
        }

        &__three {
            order: 3;
        }

        &__eight {
            order: 4;
        }

        &__nine {
            order: 5;
        }

    }

    .notre-commune {
        padding-bottom: 140px;

        &__wrapper {
            margin-top: -137px;

            &:before {
                height: 110px;
                right: -40px;
                top: 111px;
                width: 110px;
            }

            &:after {
                background-size: 35.43px;
                bottom: 88px;
                right: -24px;
                width: 35.43px;
                height: 44.91px;
            }
            
            svg {
                height: 330px;
                width: 320px;
                margin-left: -10px;
            }
        }

        &__bloc {
            &:before {
                width: 115px;
                height: 115px;
                background-size: 115px;
                top: 70px;
                bottom: unset;
                left: -10px;
            }
        }

        &__header {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        &__text {
            width: 100%;
        }

        .chiffres-commune {
            margin-top: 25px;

            &__blocs {
                gap: 30px 55px;
                flex-wrap: wrap;
            }
        }
    }
}
