.cover {
    background-color: $color-third;
    position: relative;
    padding-bottom: 75px;

    &:before, &:after {
        position: absolute;
        content: " ";
        width: 100%;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
    }

    &:before {
        height: 225px;
        top: -225px;
        background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/vague_top_top_post.svg);
    }

    &:after {
        height: 100px;
        bottom: -11px;
        background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/vagues_top_css.svg);
    }

    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 200px;
            height: 200px;
            left: -100px;
            top: -130px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/motif_gris.png);
            border-radius: 500px;
            z-index: -1;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        margin-top: 228px;
    }

    &__title {
        font-family: $font-family;
        font-weight: 700;
        font-size: 50px;
        line-height: 50px;
        margin: 0;
        margin-top: 20px;
    }

    &__lead-wrapper {
        font-family: $font-family--heading;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-top: 10px;
    }

    &__image-wrapper {
        margin-top: -224px;
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 58px;
            height: 73px;
            left: -23px;
            top: 258px;
            background-image: url(/wp-content/themes/javene/assets/src/images/sprite-images/home/bandes_vertes.svg);
            background-repeat: no-repeat;
            z-index: 1;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        padding-bottom: 55px;

        &:before {
            background-size: 1500px;
            left: calc(50% - 1500px/2);
            top: -158px;
            width: 1500px;
            height: 160px;
        }

        &:after {
            background-size: 1500px;
            width: 1500px;
            height: 70.2px;
            left: calc(50% - 1500px/2);
        }

        &__container {
            &:before {
                width: 150px;
                height: 150px;
                top: -85px;
                left: -60px;
            }
        }

        &__title {
            margin: 0;
            font-size: 40px;
            line-height: 40px;
        }

        &__image-wrapper {
            margin-top: -155px;

            svg {
                width: 480px;
                height: 380px;
            }

            &:before {
                top: 290px;
                left: 22px;
            }
        }

        &__content {
            margin-top: 147px;
        }

        &__informations {
            margin-top: 25px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        padding-bottom: 70px;

        &:before {
            background-size: 1200px;
            width: 1200px;
            height: 120px;
            left: calc(50% - 1200px/2);
            top: -120px;
        }

        &:after {
            background-size: 1200px;
            width: 1200px;
            height: 55.8px;
            left: calc(50% - 1200px/2);
        }

        &__container {
            &:before {
                left: -220px;
            }
        }

        &__content {
            flex-direction: column-reverse;
            gap: 20px;
            margin-top: 163px;
        }

        &__image-wrapper {
            margin-top: -145px;
            margin-left: 10px;

            &:before {
                left: -10px;
                top: 325px;
            }

            svg {
                width: 600px;
                height: 475px;
            }
        }

        &__informations {
            margin-top: 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        padding-bottom: 57px;

        &:before {
            background-size: 960px;
            width: 960px;
            left: calc(50% - 960px/2);
            height: 95px;
            top: -95px;
        }

        &:after {
            background-size: 960px;
            width: 960px;
            height: 50px;
            left: calc(50% - 960px/2);
        }

        &__container {
            &:before {
                display: none;
            }
        }

        &__image-wrapper {
            margin-left: -10px;
            margin-top: -162px;

            &:before {
                left: 10px;
                top: 187px;
            }

            svg {
                width: 320px;
                height: 253px;
            }
        }

        &__content {
            margin-top: 182px;
        }
    }

}
